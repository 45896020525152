import { Injectable, Optional, Inject } from "@angular/core";
import { DateFnsAdapter } from "@angular/material-date-fns-adapter";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { TimezoneService } from "./timezone.service";
import {
  getMonth,
  getYear,
  getDate,
  getDay,
  getHours,
  getMinutes,
  getSeconds,
  getDaysInMonth,
  formatISO,
  addYears,
  addMonths,
  addDays,
  addSeconds,
  format,
  parseISO
} from "date-fns";
import { c_TZ_DATE, get_browser_timezone } from "./date.utils";

@Injectable()
export class TimezoneDateAdapter extends DateFnsAdapter {
  private _timezone: string = "UTC";

  constructor(
    @Optional() @Inject(MAT_DATE_LOCALE) dateLocale: string,
    private timezone_service: TimezoneService) {
    super(dateLocale);

    // Subscribe to timezone changes
    this.timezone_service.timezone$.subscribe((tz) => {
      const browser_tz = get_browser_timezone();
      if (tz !== browser_tz) {
        console.warn(`Selected timezone "${tz}" differs from local browser timezone "${browser_tz}"!`);
      }
      this._timezone = tz;
    });
  }

  /**
   * Override `getValidDateOrNull` to ensure that the date picker displays the correct date.
   */
  override getValidDateOrNull(obj: any): Date | null {
    const date = super.getValidDateOrNull(obj);
    if (date) {
      const tz_date = new c_TZ_DATE(date, this._timezone);
      return tz_date;
    }
    return null;
  }

  // new overrides based on c_TZ_DATE
  override getYear(date: Date): number {
    const tz_date = new c_TZ_DATE(date, this._timezone);
    return getYear(tz_date);
  }

  override getMonth(date: Date): number {
    const tz_date = new c_TZ_DATE(date, this._timezone);
    return getMonth(tz_date);
  }

  override getDate(date: Date): number {
    const tz_date = new c_TZ_DATE(date, this._timezone);
    return getDate(tz_date);
  }

  override getDayOfWeek(date: Date): number {
    const tz_date = new c_TZ_DATE(date, this._timezone);
    return getDay(tz_date);
  }

  override getYearName(date: Date): string {
    const tz_date = new c_TZ_DATE(date, this._timezone);
    return this.format(tz_date, "y");
  }

  override getNumDaysInMonth(date: Date): number {
    const tz_date = new c_TZ_DATE(date, this._timezone);
    return getDaysInMonth(tz_date);
  }

  override clone(date: Date): Date {
    return new c_TZ_DATE(date.getTime(), this._timezone);
  }

  override createDate(year: number, month: number, date: number): Date {
    const result = new c_TZ_DATE(year, month, date, this._timezone);
    return result;
  }

  override today(): Date {
    return new c_TZ_DATE(new Date(), this._timezone);
  }

  override parse(value: any, parseFormat: string | string[]): Date | null {
    const date = super.parse(value, parseFormat);
    if (date) {
      const tz_date = new c_TZ_DATE(date, this._timezone);
      return tz_date;
    }
    return null;
  }

  override format(date: Date, displayFormat: string): string {
    if (!this.isValid(date)) {
      throw Error("DateFnsAdapter: Cannot format invalid date.");
    }
    const tz_date = new c_TZ_DATE(date, this._timezone);
    return format(tz_date, displayFormat, { locale: this.locale });
  }

  override addCalendarYears(date: Date, years: number): Date {
    const tz_date = new c_TZ_DATE(date, this._timezone);
    return addYears(tz_date, years);
  }

  override addCalendarMonths(date: Date, months: number): Date {
    const tz_date = new c_TZ_DATE(date, this._timezone);
    return addMonths(tz_date, months);
  }

  override addCalendarDays(date: Date, days: number): Date {
    const tz_date = new c_TZ_DATE(date, this._timezone);
    return addDays(tz_date, days);
  }

  override toIso8601(date: Date): string {
    const tz_date = new c_TZ_DATE(date, this._timezone);
    return formatISO(tz_date, { representation: "date" });
  }

  /**
   * Returns the given value if given a valid Date or null. Deserializes valid ISO 8601 strings
   * (https://www.ietf.org/rfc/rfc3339.txt) into valid Dates and empty string into null. Returns an
   * invalid date for all other values.
   */
  override deserialize(value: any): Date | null {
    if (typeof value === "string") {
      if (!value) {
        return null;
      }
      const date = parseISO(value);
      const tz_date = new c_TZ_DATE(date, this._timezone);
      if (this.isValid(tz_date)) {
        return tz_date;
      }
    }
    return super.deserialize(value);
  }

  override setTime(target: Date, hours: number, minutes: number, seconds: number): Date {
    const result = new c_TZ_DATE(super.setTime(target, hours, minutes, seconds), this._timezone);
    return result;
  }

  override getHours(date: Date): number {
    const tz_date = new c_TZ_DATE(date, this._timezone);
    return getHours(tz_date);
  }

  override getMinutes(date: Date): number {
    const tz_date = new c_TZ_DATE(date, this._timezone);
    return getMinutes(tz_date);
  }

  override getSeconds(date: Date): number {
    const tz_date = new c_TZ_DATE(date, this._timezone);
    return getSeconds(tz_date);
  }

  override parseTime(value: any, parseFormat: string | string[]): Date | null {
    return this.parse(value, parseFormat);
  }

  override addSeconds(date: Date, amount: number): Date {
    const tz_date = new c_TZ_DATE(date, this._timezone);
    return addSeconds(tz_date, amount);
  }
}
